@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500;600;700&family=Poppins:wght@400;500;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Urbanist:ital,wght@0,200;0,500;0,700;0,800;0,900;1,100;1,200;1,500;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

body {
  overflow-x: hidden;
}

.home{
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

#tutor-btn {
  background: linear-gradient(
    148deg,
    rgba(45, 98, 122, 1) 6%,
    rgba(14, 70, 97, 1) 34%,
    rgba(5, 48, 69, 1) 66%,
    rgba(5, 48, 69, 1) 100%
  );
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

#tutor-btn:hover {
  transition: all 0.3s ease-in-out;
  color: #010606;
  background: #fff;
}

/* Browser track css */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #053146;
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
